import React from 'react';
import "./Home.css";
import { Container, Col, Row, CardGroup, Card } from 'react-bootstrap';
import AppStore from '../../assets/images/app-store-badge.svg';
import PlayStore from "../../assets/images/google-play-badge.png";
import Mobile from '../../assets/images/mobile-screen.jpg';
import { isAndroid } from "react-device-detect";

const Home = (props) => {
    const { shareId } = props.match.params;
    const appPackageName = 'com.virtusmart.realsmartclient';
    const rscWebBaseUrl = window._env_.REALSMART_CLIENT_WEB;
    const androidStoreLink = isAndroid
        ? `intent:${rscWebBaseUrl}/app-invite/${shareId || ''}#Intent;package=${appPackageName};end`
        : `https://play.google.com/store/apps/details?id=${appPackageName}`;

    return (
        <div className="d-flex content-center w-100 panel-content">
            <Container>
                <CardGroup className="pb-0 card-group-content">
                    <Card className="home-card pb-0">
                        <Row>
                            <Col sm={5} md={5} className="d-flex align-items-end">
                                <div className="overflow-hidden">
                                    <Card.Img src={Mobile} alt="Mobile" className="mobile-img" fluid />
                                </div>
                            </Col>
                            <Col sm={7} md={7} className="d-flex pl-0 align-items-center">
                                <Card.Body className="pr-0">
                                    <Card.Title as="h2" className="card-title">
                                        <div>Download the</div>
                                        <div>HomeSmart Client app</div>
                                    </Card.Title>
                                    <Card.Text className="card-text pr-0">
                                        <div className="app-store-icon">
                                            <a className="app-store-badge mt-2 mr-3 d-inline-block" href="https://apps.apple.com/ca/app/homesmart-client/id1561384052" target="_blank">
                                                <img alt="Apple App Store" src={AppStore} />
                                            </a>
                                            <a className="play-store-badge mt-2 d-inline-block" href={androidStoreLink} target="_blank">
                                                <img alt="Google Play Store" src={PlayStore} />
                                            </a>
                                        </div>
                                        <div className="hr-depth w-100" />
                                        <div>
                                            Download the HomeSmart Client app for customized home search and real-time updates at every stage of your transaction. Browse listings, get pre-qualified for a loan, connect with an agent, Schedule showings and oversee the process as the deals comes together. Get HomeSmart Client to start your home search, today!
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </CardGroup>
            </Container>
        </div>
    );
}

export default Home;