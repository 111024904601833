import * as actionTypes from '../actions/actionTypes';
import * as constants from '../../constants';
import { updateState } from '../../lib/stateHelper';

const initialState = {
    lastState: null,
    alertTitle: '',
    alertMessage: '',
    showAlert: false,
    isProcessing: false,
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FORGOT_PASSWORD_RESET: {
            return updateState(state, { ...initialState });
        }
        case actionTypes.FORGOT_PASSWORD_START: {
            return updateState(state, { ...initialState, isProcessing: true, lastState: constants.STATE_PROCESSING });
        }
        case actionTypes.FORGOT_PASSWORD_SUCCESS: {
            const newState = {
                isProcessing: false,
            };
            return updateState(state, { ...newState, lastState: constants.STATE_SUCCESS });
        }
        case actionTypes.FORGOT_PASSWORD_FAIL: {
            const newState = {
                alertTitle: action.error.status,
                alertMessage: action.error.message,
                showAlert: true,
                isProcessing: false,
            };
            return updateState(state, { ...newState, lastState: constants.STATE_FAIL });
        }
        case actionTypes.FORGOT_PASSWORD_TOGGLE_ALERT: {
            const newState = state.showAlert ? {
                ...initialState
            } : {
                showAlert: true
            };
            return updateState(state, { ...newState });
        }
        default: {
            return state;
        }
    }
};

export default forgotPasswordReducer;