import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import HSCLogo from "../../assets/images/hsc-logo.png";
import './Header.css';

const Header = () => {
    return <Container className="header-box" fluid>
        <Row className="h-100">
            <Col className="h-100">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <Image src={HSCLogo} fluid className="h-50"/>
                </div>
            </Col>
        </Row>
    </Container>
};

export default Header;