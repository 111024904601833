import * as actionTypes from './actionTypes';
import axios from 'axios';
const API = axios.create({
    baseURL: window._env_.REALSMART_API
});

export const updatePasswordStart = () => {
    return {
        type: actionTypes.FORGOT_PASSWORD_START,
    };
};

export const updatePasswordSuccess = () => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    };
};

export const updatePasswordFail = (error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_FAIL,
        error: {
            status:
                error.response &&
                error.response.status &&
                error.response.status,
            message:
                error.response &&
                error.response.data &&
                error.response.data.message &&
                error.response.data.message,
        },
    };
};

export const updatePassword = (userId, hash, password) => {
    return (dispatch) => {
        dispatch(updatePasswordStart());
        const payload = {
            userId,
            hash,
            password,
        };

        const headers = {
            'Content-Type': 'application/json'
        }

        API.post('/account/UpdatePassword', payload, headers).then((response) => {
            dispatch(updatePasswordSuccess());
        }).catch((error) => {
            dispatch(updatePasswordFail(error));
        })
    }
};

export const toggleAlert = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_PASSWORD_TOGGLE_ALERT
        });
    };
}

export const resetState = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.FORGOT_PASSWORD_RESET
        });
    };
}