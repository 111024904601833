import './App.css';
import { Route } from 'react-router'
import Home from './pages/Home/Home';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppInvite from './pages/AppInvite/AppInvite';

function App() {
  return (
    <>
      <Header />
      <div className="root-content">
        <Route exact path="/" component={Home} />
          <Route exact path="/forgot-password/:userId/:hash" component={ForgotPassword} />
          <Route exact path="/app-invite/:shareId" component={AppInvite} />
      </div>
      <Footer />
    </>
  );
}

export default App;
