import React, { useEffect, useState } from 'react';
import "./ForgotPassword.css";
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword, toggleAlert, resetState } from '../../store/actions/forgotPassword';
import * as constants from '../../constants';

const ForgotPassword = (props) => {
    const pageState = useSelector(state => state.forgotPassword);
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        if (!isPageLoaded && pageState.lastState !== null) {
            dispatch(resetState());
        }
        setIsPageLoaded(true);
    }, [pageState.lastState]);

    const onSumbit = () => {
        let validationErrors = [];
        if (!Boolean(newPassword) || !Boolean(confirmPassword)) {
            validationErrors.push('Please fill all fields.');
        }

        if (newPassword !== confirmPassword) {
            validationErrors.push('Confirm Password should match the New Password.');
        }

        const passwordStrengthRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (!passwordStrengthRegex.test(newPassword)) {
            validationErrors.push('Strong passwords with min 8 - max 15 character length, at least one uppercase letter, one lowercase letter, one number, one special character, space is not allowed.');
        }

        setErrors(validationErrors);

        if (validationErrors.length === 0) {
            const { userId, hash } = props.match.params;
            dispatch(updatePassword(userId, hash, newPassword));
            setNewPassword('');
            setConfirmPassword('');
        }
    }

    const resetErrors = () => {
        setErrors([]);
        if (pageState.showAlert) {
            dispatch(toggleAlert());
        }
    }

    return (<div className="forgot-password-container">
        <Container className="content-body">
            <Row className="h-100">
                <Col className="d-flex justify-content-center align-items-center">
                    <div className="center-panel">
                        {
                            pageState.lastState === constants.STATE_SUCCESS
                                ? <Container>
                                    <Row>
                                        <Col className="display-3 text-center text-success">
                                            <i className="bi bi-check2-circle"></i>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="h5 text-center">
                                            Your password updated successfully.
                                        </Col>
                                    </Row>
                                </Container>
                                : <Container>
                                    <Row>
                                        <Col className="h3 text-center mb-3">
                                            Enter your new password below.
                                        </Col>
                                    </Row>
                                    <Row className="mb-4 justify-content-center">
                                        <Col className="h5 col-lg-7 text-center password-info">
                                            Great passwords use upper and lower case characters, numbers and symbols.
                                        </Col>
                                    </Row>
                                    {
                                        errors.length > 0 &&
                                        <Row>
                                            <Col>
                                                <ul>
                                                    {
                                                        errors.map((error, index) => {
                                                            return <li key={index} className="text-danger">{error}</li>
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <Container fluid className="p-0 mb-3">
                                                <Row noGutters className="align-items-center">
                                                    <Col md={4} className="h5 mb-md-0 input-label">
                                                        <strong>New Password</strong>
                                                    </Col>
                                                    <Col md={7} className="ml-md-4">
                                                        <input type="password" className="form-control" value={newPassword} disabled={pageState.isProcessing} onChange={e => { resetErrors(); setNewPassword(e.target.value) }} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Container fluid className="p-0 mb-3">
                                                <Row noGutters className="align-items-center">
                                                    <Col md={4} className="h5 mb-md-0 input-label">
                                                        <strong>Confirm Password</strong>
                                                    </Col>
                                                    <Col md={7} className="ml-md-4">
                                                        <input type="password" className="form-control" value={confirmPassword} disabled={pageState.isProcessing} onChange={e => { resetErrors(); setConfirmPassword(e.target.value) }} />
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center my-3">
                                            <Button type="button" onClick={onSumbit} className="reset-btn" disabled={pageState.isProcessing}>
                                                {pageState.isProcessing ? "Resetting..." : "Reset Password"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
        <Alert show={pageState.showAlert} variant="danger" className="alert-box" dismissible onClose={() => dispatch(toggleAlert())}>
            <Alert.Heading>Error {pageState.alertTitle}</Alert.Heading>
            <p>{pageState.alertMessage}</p>
        </Alert>
    </div>);
}

export default ForgotPassword;