import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import './Footer.css';
import HSLogo from "../../assets/images/hs-logo.png";

const Footer = () => {
    return <Container className="footer-box w-100" fluid>
        <Row>
            <Col>
                <div className="d-flex justify-content-center align-items-center mb-2 mb-md-3">
                    <Image src={HSLogo} fluid />
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="text-center footer-info">
                &copy; Copyright 2020 HomeSmart International, LLC. All rights reserved.<br />Each brokerage independently owned and operated.
            </Col>
        </Row>
    </Container>
};

export default Footer;