import React, { useEffect, useRef } from 'react';
import "./AppInvite.css";
import { Button, Col, Container, Row } from 'react-bootstrap';
import AppStore from '../../assets/images/app-store-badge.svg';
import PlayStore from "../../assets/images/google-play-badge.png";
import Home from '../Home/Home';
import { isMobile, isAndroid, isIOS } from "react-device-detect";

const AppInvite = (props) => {
    const { shareId } = props.match.params;

    useEffect(() => {
        if (isMobile) {
            const MobilePlatformEnum = {
                android: 1,
                iOS: 2
            };

            const registerUrl = `${window._env_.REALSMART_API}/appinvite?agentShareId=${shareId}&platform=${isAndroid
                ? MobilePlatformEnum.android
                : MobilePlatformEnum.iOS}`;
            window.location = registerUrl;
        }
    }, []);


    return <Home {...props} />;
}

export default AppInvite;